export const basicBorder = "#D1D5DB";
export const basicContainerBackground = "#FFFFFF"; // WARNING: 2 duplicates
export const basicContainerBackgroundDark = "#171717"; // WARNING: 2 duplicates
export const basicOverlayBackground = "#0A0A0A80";
export const basicTextPrimary = "#030712"; // WARNING: 2 duplicates
export const basicTextSecondary = "#6B7280";
export const brandBrand = "#2563EB"; // WARNING: 2 duplicates
export const brandBrandBorder = "#93C5FD"; // WARNING: 2 duplicates
export const brandBrandForeground = "#EFF6FF"; // WARNING: 2 duplicates
export const brandBrandSoft = "#DBEAFE"; // WARNING: 2 duplicates
export const brandBrandSoftForeground = "#1E3A8A"; // WARNING: 2 duplicates
export const destructiveDestructive = "#DC2626"; // WARNING: 2 duplicates
export const destructiveDestructiveBorder = "#FCA5A5"; // WARNING: 2 duplicates
export const destructiveDestructiveForeground = "#FEF2F2"; // WARNING: 2 duplicates
export const destructiveDestructiveSoft = "#FEE2E2"; // WARNING: 2 duplicates
export const destructiveDestructiveSoftForeground = "#7F1D1D"; // WARNING: 2 duplicates
export const focusRing = "#0091FF80";
export const fontLg = "1.25rem"; // WARNING: 2 duplicates
export const fontMd = "1rem"; // WARNING: 2 duplicates
export const fontSm = "0.75rem"; // WARNING: 2 duplicates
export const iconSize = "16px";
export const interactionDisabled = "#FFFFFF40"; // WARNING: 2 duplicates
export const interactionHovered = "#0000001A"; // WARNING: 2 duplicates
export const interactionPressed = "#00000033"; // WARNING: 2 duplicates
export const lineHeightLg = "1.4"; // WARNING: 2 duplicates
export const lineHeightMd = "1.25"; // WARNING: 2 duplicates
export const lineHeightSm = "1"; // WARNING: 2 duplicates
export const listItemGap = "8px";
export const listItemHPadding = "16px";
export const listItemHeight = "32px";
export const listSectionHeaderHeight = "44px";
export const multiplayerColor01 = "#30A46C";
export const multiplayerColor02 = "#68DDFD";
export const multiplayerColor03 = "#8E4EC6";
export const multiplayerColor04 = "#05A2C2";
export const multiplayerColor05 = "#D6409F";
export const multiplayerColor06 = "#F5D90A";
export const multiplayerColor07 = "#F76808";
export const mutedMuted = "#6B7280"; // WARNING: 2 duplicates
export const mutedMutedBorder = "#E5E7EB"; // WARNING: 2 duplicates
export const mutedMutedForeground = "#E5E7EB"; // WARNING: 2 duplicates
export const mutedMutedSoft = "#F3F4F6"; // WARNING: 2 duplicates
export const mutedMutedSoftForeground = "#6B7280"; // WARNING: 2 duplicates
export const mutedMutedSoftHover = "#E5E7EB";
export const neutralNeutral = "#374151"; // WARNING: 2 duplicates
export const neutralNeutralBorder = "#D1D5DB"; // WARNING: 2 duplicates
export const neutralNeutralForeground = "#F9FAFB"; // WARNING: 2 duplicates
export const neutralNeutralSoft = "#E5E7EB"; // WARNING: 2 duplicates
export const neutralNeutralSoftForeground = "#111827"; // WARNING: 2 duplicates
export const sansSerif = "Inter"; // WARNING: 2 duplicates
export const size10 = "0.625rem"; // WARNING: 2 duplicates
export const size12 = "0.75rem"; // WARNING: 2 duplicates
export const size16 = "1rem"; // WARNING: 2 duplicates
export const size2 = "0.125rem"; // WARNING: 2 duplicates
export const size24 = "1.5rem";
export const size32 = "2rem";
export const size4 = "0.25rem"; // WARNING: 2 duplicates
export const size6 = "0.375rem"; // WARNING: 2 duplicates
export const size8 = "0.5rem"; // WARNING: 2 duplicates
export const spectrumBlue15 = "rgb(5,33,77)";
export const spectrumBlue60 = "rgb(69,153,255)";
export const spectrumBlue95 = "rgb(231,243,255)";
export const spectrumCoral40 = "rgb(224,62,40)"; // WARNING: 4 duplicates
export const spectrumCoral50 = "rgb(245,83,61)"; // WARNING: 3 duplicates
export const spectrumCoral80 = "rgb(255,186,176)";
export const spectrumCoral95 = "rgb(255,230,227)"; // WARNING: 3 duplicates
export const spectrumCyan40 = "rgb(0,110,173)";
export const spectrumCyan50 = "rgb(0,139,208)"; // WARNING: 2 duplicates
export const spectrumCyan55 = "rgb(0,153,230)";
export const spectrumCyan60 = "rgb(4,164,244)"; // WARNING: 4 duplicates
export const spectrumCyan70 = "rgb(79,196,251)";
export const spectrumCyan95 = "rgb(224,247,255)"; // WARNING: 2 duplicates
export const spectrumGray00 = "rgb(5,5,5)"; // WARNING: 2 duplicates
export const spectrumGray05 = "rgb(14,15,15)"; // WARNING: 2 duplicates
export const spectrumGray100 = "rgb(245,247,250)"; // WARNING: 4 duplicates
export const spectrumGray20 = "rgb(50,52,54)"; // WARNING: 5 duplicates
export const spectrumGray30 = "rgb(74,76,79)"; // WARNING: 2 duplicates
export const spectrumGray50 = "rgb(122,125,130)"; // WARNING: 3 duplicates
export const spectrumGray55 = "rgb(138,141,145)";
export const spectrumGray60 = "rgb(150,153,158)"; // WARNING: 4 duplicates
export const spectrumGray65 = "rgb(164,167,171)"; // WARNING: 4 duplicates
export const spectrumGray80 = "rgb(201,204,209)"; // WARNING: 2 duplicates
export const spectrumGray85 = "rgb(215,218,222)";
export const spectrumGray90 = "rgb(228,230,235)"; // WARNING: 3 duplicates
export const spectrumGray95 = "rgb(240,242,245)"; // WARNING: 6 duplicates
export const spectrumGreen50 = "rgb(49,162,76)";
export const spectrumGreen60 = "rgb(69,189,98)";
export const spectrumRed35 = "rgb(228,30,63)"; // WARNING: 2 duplicates
export const spectrumRed40 = "rgb(240,40,73)";
export const spectrumYellow30 = "rgb(181,84,0)";
export const spectrumYellow55 = "rgb(241,168,23)";
export const spectrumYellow60 = "rgb(247,185,40)";
export const spectrumYellow95 = "rgb(255,249,209)"; // WARNING: 2 duplicates
export const successSuccess = "#16A34A"; // WARNING: 2 duplicates
export const successSuccessBorder = "#86EFAC"; // WARNING: 2 duplicates
export const successSuccessForeground = "#F0FDF4"; // WARNING: 2 duplicates
export const successSuccessSoft = "#DCFCE7"; // WARNING: 2 duplicates
export const successSuccessSoftForeground = "#14532D"; // WARNING: 2 duplicates
export const tabItemSelectedBg = "#E1F0FF";
export const tabItemSelectedForeground = "#000000E8";
export const warningWarning = "#FACC15"; // WARNING: 2 duplicates
export const warningWarningBorder = "#FDE047"; // WARNING: 2 duplicates
export const warningWarningForeground = "#422006"; // WARNING: 2 duplicates
export const warningWarningSoft = "#FEF9C3"; // WARNING: 2 duplicates
export const warningWarningSoftForeground = "#713F12"; // WARNING: 2 duplicates
export const activeBg = "#EDF6FF";
export const activeFg = "#0091FF";
export const black = "#000000";
export const blackA1 = "#00000003";
export const blackA10 = "#0000007A";
export const blackA11 = "#00000091";
export const blackA12 = "#000000E8";
export const blackA2 = "#00000008";
export const blackA3 = "#0000000D";
export const blackA4 = "#00000012";
export const blackA5 = "#00000017";
export const blackA6 = "#0000001C";
export const blackA7 = "#00000024";
export const blackA8 = "#00000038";
export const blackA9 = "#00000070";
export const blue1 = "#FBFDFF";
export const blue10 = "#0081F1";
export const blue11 = "#006ADC";
export const blue12 = "#00254D";
export const blue2 = "#F5FAFF";
export const blue3 = "#EDF6FF";
export const blue4 = "#E1F0FF";
export const blue5 = "#CEE7FE";
export const blue6 = "#B7D9F8";
export const blue7 = "#96C7F2";
export const blue8 = "#5EB0EF";
export const blue9 = "#0091FF";
export const blueDark1 = "#0F1720";
export const blueDark10 = "#369EFF";
export const blueDark11 = "#52A9FF";
export const blueDark12 = "#EAF6FF";
export const blueDark2 = "#0F1B2D";
export const blueDark3 = "#10243E";
export const blueDark4 = "#102A4C";
export const blueDark5 = "#0F3058";
export const blueDark6 = "#0D3868";
export const blueDark7 = "#0A4481";
export const blueDark8 = "#0954A5";
export const blueDark9 = "#0091FF";
export const borderColor = "#E3E3E0";
export const brown1 = "#FEFDFC";
export const brown10 = "#A07653";
export const brown11 = "#886349";
export const brown12 = "#3F2C22";
export const brown2 = "#FCF9F6";
export const brown3 = "#F8F1EA";
export const brown4 = "#F4E9DD";
export const brown5 = "#EFDDCC";
export const brown6 = "#E8CDB5";
export const brown7 = "#DDB896";
export const brown8 = "#D09E72";
export const brown9 = "#AD7F58";
export const brownDark1 = "#191513";
export const brownDark10 = "#BD8B60";
export const brownDark11 = "#DBA16E";
export const brownDark12 = "#FAF0E5";
export const brownDark2 = "#221813";
export const brownDark3 = "#2E201A";
export const brownDark4 = "#36261E";
export const brownDark5 = "#3E2C22";
export const brownDark6 = "#3E2C22";
export const brownDark7 = "#5C4332";
export const brownDark8 = "#775940";
export const brownDark9 = "#AD7F58";
export const crimson1 = "#FFFCFD";
export const crimson10 = "#E03177";
export const crimson11 = "#D31E66";
export const crimson12 = "#3D0D1D";
export const crimson2 = "#FFF7FB";
export const crimson3 = "#FEEFF6";
export const crimson4 = "#FCE5F0";
export const crimson5 = "#F9D8E7";
export const crimson6 = "#F4C6DB";
export const crimson7 = "#EDADC8";
export const crimson8 = "#E58FB1";
export const crimson9 = "#E93D82";
export const crimsonDark1 = "#1D1418";
export const crimsonDark10 = "#F04F88";
export const crimsonDark11 = "#F76190";
export const crimsonDark12 = "#FEECF4";
export const crimsonDark2 = "#27141C";
export const crimsonDark3 = "#3C1827";
export const crimsonDark4 = "#481A2D";
export const crimsonDark5 = "#541B33";
export const crimsonDark6 = "#641D3B";
export const crimsonDark7 = "#801D45";
export const crimsonDark8 = "#AE1955";
export const crimsonDark9 = "#E93D82";
export const cyan1 = "#FAFDFE";
export const cyan10 = "#0894B3";
export const cyan11 = "#0C7792";
export const cyan12 = "#04313C";
export const cyan2 = "#F2FCFD";
export const cyan3 = "#E7F9FB";
export const cyan4 = "#D8F3F6";
export const cyan5 = "#C4EAEF";
export const cyan6 = "#AADEE6";
export const cyan7 = "#84CDDA";
export const cyan8 = "#3DB9CF";
export const cyan9 = "#05A2C2";
export const cyanDark1 = "#07191D";
export const cyanDark10 = "#00B1CC";
export const cyanDark11 = "#00C2D7";
export const cyanDark12 = "#E1F8FA";
export const cyanDark2 = "#061E24";
export const cyanDark3 = "#072830";
export const cyanDark4 = "#07303B";
export const cyanDark5 = "#073844";
export const cyanDark6 = "#064150";
export const cyanDark7 = "#045063";
export const cyanDark8 = "#00647D";
export const cyanDark9 = "#05A2C2";
export const enterpriseBackground = "#161615";
export const gold1 = "#FDFDFC";
export const gold10 = "#8C795D";
export const gold11 = "#776750";
export const gold12 = "#3B352B";
export const gold2 = "#FBF9F2";
export const gold3 = "#F5F2E9";
export const gold4 = "#EEEADD";
export const gold5 = "#E5DFD0";
export const gold6 = "#DAD1BD";
export const gold7 = "#CBBDA4";
export const gold8 = "#B8A383";
export const gold9 = "#978365";
export const goldDark1 = "#171613";
export const goldDark10 = "#A59071";
export const goldDark11 = "#BFA888";
export const goldDark12 = "#F7F4E7";
export const goldDark2 = "#1C1A15";
export const goldDark3 = "#26231C";
export const goldDark4 = "#2E2A21";
export const goldDark5 = "#353026";
export const goldDark6 = "#3E382C";
export const goldDark7 = "#504737";
export const goldDark8 = "#6B5D48";
export const goldDark9 = "#978365";
export const grass1 = "#FBFEFB";
export const grass10 = "#3D9A50";
export const grass11 = "#297C3B";
export const grass12 = "#1B311E";
export const grass2 = "#F3FCF3";
export const grass3 = "#EBF9EB";
export const grass4 = "#DFF3DF";
export const grass5 = "#CEEBCF";
export const grass6 = "#B7DFBA";
export const grass7 = "#97CF9C";
export const grass8 = "#65BA75";
export const grass9 = "#46A758";
export const grassDark1 = "#0D1912";
export const grassDark10 = "#55B467";
export const grassDark11 = "#63C174";
export const grassDark12 = "#E5FBEB";
export const grassDark2 = "#0F1E13";
export const grassDark3 = "#132819";
export const grassDark4 = "#16301D";
export const grassDark5 = "#193921";
export const grassDark6 = "#1D4427";
export const grassDark7 = "#245530";
export const grassDark8 = "#2F6E3B";
export const grassDark9 = "#46A758";
export const green1 = "#FBFEFC";
export const green10 = "#299764";
export const green11 = "#18794E";
export const green12 = "#153226";
export const green2 = "#F2FCF5";
export const green3 = "#E9F9EE";
export const green4 = "#DDF3E4";
export const green5 = "#CCEBD7";
export const green6 = "#B4DFC4";
export const green7 = "#92CEAC";
export const green8 = "#5BB98C";
export const green9 = "#30A46C";
export const greenDark1 = "#0D1912";
export const greenDark10 = "#3CB179";
export const greenDark11 = "#4CC38A";
export const greenDark12 = "#E5FBEB";
export const greenDark2 = "#0C1F17";
export const greenDark3 = "#0F291E";
export const greenDark4 = "#113123";
export const greenDark5 = "#133929";
export const greenDark6 = "#164430";
export const greenDark7 = "#1B543A";
export const greenDark8 = "#236E4A";
export const greenDark9 = "#30A46C";
export const h1FontSize = "72px";
export const h2FontSize = "48px";
export const h3FontSize = "32px";
export const h4FontSize = "24px";
export const h5FontSize = "20px";
export const h6FontSize = "16px";
export const indigo1 = "#FDFDFE";
export const indigo10 = "#3A5CCC";
export const indigo11 = "#3451B2";
export const indigo12 = "#101D46";
export const indigo2 = "#F8FAFF";
export const indigo3 = "#F0F4FF";
export const indigo4 = "#E6EDFE";
export const indigo5 = "#D9E2FC";
export const indigo6 = "#C6D4F9";
export const indigo7 = "#AEC0F5";
export const indigo8 = "#8DA4EF";
export const indigo9 = "#3E63DD";
export const indigoDark1 = "#131620";
export const indigoDark10 = "#5373E7";
export const indigoDark11 = "#849DFF";
export const indigoDark12 = "#EEF1FD";
export const indigoDark2 = "#15192D";
export const indigoDark3 = "#192140";
export const indigoDark4 = "#1C274F";
export const indigoDark5 = "#1F2C5C";
export const indigoDark6 = "#22346E";
export const indigoDark7 = "#273E89";
export const indigoDark8 = "#2F4EB2";
export const indigoDark9 = "#3E63DD";
export const inheritedBorder = "#00000024";
export const mediumFontSize = "16px";
export const neutral500 = "#666D80";
export const neutral600 = "#36394A";
export const neutralPrimary = "#1B1B18";
export const neutralSecondary = "#706F6C";
export const normalFontSize = "12px";
export const orange1 = "#FEFCFB";
export const orange10 = "#ED5F00";
export const orange11 = "#BD4B00";
export const orange12 = "#451E11";
export const orange2 = "#FEF8F4";
export const orange3 = "#FFF1E7";
export const orange4 = "#FFE8D7";
export const orange5 = "#FFDCC3";
export const orange6 = "#FFCCA7";
export const orange7 = "#FFB381";
export const orange8 = "#FA934E";
export const orange9 = "#F76808";
export const orangeDark1 = "#1F1206";
export const orangeDark10 = "#FF802B";
export const orangeDark11 = "#FF8B3E";
export const orangeDark12 = "#FEEADD";
export const orangeDark2 = "#2B1400";
export const orangeDark3 = "#391A03";
export const orangeDark4 = "#441F04";
export const orangeDark5 = "#4F2305";
export const orangeDark6 = "#5F2A06";
export const orangeDark7 = "#763205";
export const orangeDark8 = "#943E00";
export const orangeDark9 = "#F76808";
export const panelBorder = "rgb(228,230,235)"; // WARNING: 2 duplicates
export const pink1 = "#FFFCFE";
export const pink10 = "#D23197";
export const pink11 = "#CD1D8D";
export const pink12 = "#3B0A2A";
export const pink2 = "#FFF7FC";
export const pink3 = "#FEEEF8";
export const pink4 = "#FCE5F3";
export const pink5 = "#F9D8EC";
export const pink6 = "#F3C6E2";
export const pink7 = "#ECADD4";
export const pink8 = "#E38EC3";
export const pink9 = "#D6409F";
export const pinkDark1 = "#1F121B";
export const pinkDark10 = "#E34BA9";
export const pinkDark11 = "#F65CB6";
export const pinkDark12 = "#FEEBF7";
export const pinkDark2 = "#271421";
export const pinkDark3 = "#3A182F";
export const pinkDark4 = "#451A37";
export const pinkDark5 = "#501B3F";
export const pinkDark6 = "#601D48";
export const pinkDark7 = "#7A1D5A";
export const pinkDark8 = "#A71873";
export const pinkDark9 = "#D6409F";
export const plum1 = "#FEFCFF";
export const plum10 = "#A43CB4";
export const plum11 = "#9C2BAD";
export const plum12 = "#340C3B";
export const plum2 = "#FFF8FF";
export const plum3 = "#FCEFFC";
export const plum4 = "#F9E5F9";
export const plum5 = "#F3D9F4";
export const plum6 = "#EBC8ED";
export const plum7 = "#DFAFE3";
export const plum8 = "#CF91D8";
export const plum9 = "#AB4ABA";
export const plumDark1 = "#1D131D";
export const plumDark10 = "#BD54C6";
export const plumDark11 = "#D864D8";
export const plumDark12 = "#FBECFC";
export const plumDark2 = "#251425";
export const plumDark3 = "#341A34";
export const plumDark4 = "#3E1D40";
export const plumDark5 = "#48214B";
export const plumDark6 = "#542658";
export const plumDark7 = "#692D6F";
export const plumDark8 = "#883894";
export const plumDark9 = "#AB4ABA";
export const purple1 = "#FEFCFE";
export const purple10 = "#8445BC";
export const purple11 = "#793AAF";
export const purple12 = "#2B0E44";
export const purple2 = "#FDFAFF";
export const purple3 = "#F9F1FE";
export const purple4 = "#F3E7FC";
export const purple5 = "#EDDBF9";
export const purple6 = "#E3CCF4";
export const purple7 = "#D3B4ED";
export const purple8 = "#BE93E4";
export const purple9 = "#8E4EC6";
export const purpleDark1 = "#1B141D";
export const purpleDark10 = "#9D5BD2";
export const purpleDark11 = "#BF7AF0";
export const purpleDark12 = "#F7ECFC";
export const purpleDark2 = "#221527";
export const purpleDark3 = "#301A3A";
export const purpleDark4 = "#3A1E48";
export const purpleDark5 = "#432155";
export const purpleDark6 = "#4E2667";
export const purpleDark7 = "#5F2D84";
export const purpleDark8 = "#7938B2";
export const purpleDark9 = "#8E4EC6";
export const red1 = "#FFFCFC";
export const red10 = "#DC3D43";
export const red11 = "#CD2B31";
export const red12 = "#381316";
export const red2 = "#FFF8F8";
export const red3 = "#FFEFEF";
export const red4 = "#FFE5E5";
export const red5 = "#FDD8D8";
export const red6 = "#F9C6C6";
export const red7 = "#F3AEAF";
export const red8 = "#EB9091";
export const red9 = "#E5484D";
export const redDark1 = "#1F1315";
export const redDark10 = "#F2555A";
export const redDark11 = "#FF6369";
export const redDark12 = "#FEECEE";
export const redDark2 = "#291415";
export const redDark3 = "#3C181A";
export const redDark4 = "#481A1D";
export const redDark5 = "#541B1F";
export const redDark6 = "#671E22";
export const redDark7 = "#822025";
export const redDark8 = "#AA2429";
export const redDark9 = "#E5484D";
export const sand1 = "#FDFDFC";
export const sand10 = "#868682";
export const sand11 = "#706F6C";
export const sand12 = "#1B1B18";
export const sand2 = "#F9F9F8";
export const sand3 = "#F3F3F2";
export const sand4 = "#EEEEEC";
export const sand5 = "#E9E9E6";
export const sand6 = "#E3E3E0";
export const sand7 = "#DBDBD7";
export const sand8 = "#C8C7C1";
export const sand9 = "#90908C";
export const sandDark1 = "#161615";
export const sandDark10 = "#7F7E77";
export const sandDark11 = "#A1A09A";
export const sandDark12 = "#EDEDEC";
export const sandDark2 = "#1C1C1A";
export const sandDark3 = "#232320";
export const sandDark4 = "#282826";
export const sandDark5 = "#2E2E2B";
export const sandDark6 = "#353431";
export const sandDark7 = "#3E3E3A";
export const sandDark8 = "#51504B";
export const sandDark9 = "#717069";
export const sky1 = "#F9FEFF";
export const sky10 = "#5FD4F4";
export const sky11 = "#0078A1";
export const sky12 = "#003242";
export const sky2 = "#F1FCFF";
export const sky3 = "#E4F9FF";
export const sky4 = "#D5F4FD";
export const sky5 = "#C1ECF9";
export const sky6 = "#A4DFF1";
export const sky7 = "#79CFEA";
export const sky8 = "#2EBDE5";
export const sky9 = "#68DDFD";
export const skyDark1 = "#0C1820";
export const skyDark10 = "#8AE8FF";
export const skyDark11 = "#2EC8EE";
export const skyDark12 = "#EAF8FF";
export const skyDark2 = "#071D2A";
export const skyDark3 = "#082636";
export const skyDark4 = "#082D41";
export const skyDark5 = "#08354C";
export const skyDark6 = "#083E59";
export const skyDark7 = "#064B6B";
export const skyDark8 = "#005D85";
export const skyDark9 = "#68DDFD";
export const teal1 = "#FAFEFD";
export const teal10 = "#0E9888";
export const teal11 = "#067A6F";
export const teal12 = "#10302B";
export const teal2 = "#F1FCFA";
export const teal3 = "#E7F9F5";
export const teal4 = "#D9F3EE";
export const teal5 = "#C7EBE5";
export const teal6 = "#AFDFD7";
export const teal7 = "#8DCEC3";
export const teal8 = "#53B9AB";
export const teal9 = "#12A594";
export const tealDark1 = "#091915";
export const tealDark10 = "#10B3A3";
export const tealDark11 = "#0AC5B3";
export const tealDark12 = "#E1FAF4";
export const tealDark2 = "#04201B";
export const tealDark3 = "#062923";
export const tealDark4 = "#07312B";
export const tealDark5 = "#083932";
export const tealDark6 = "#09443C";
export const tealDark7 = "#0B544A";
export const tealDark8 = "#0C6D62";
export const tealDark9 = "#12A594";
export const textInherited = "#706F6C";
export const textSet = "#1B1B18";
export const textUnset = "#90908C";
export const textboxdisabled = "#1B1B1880";
export const textboxempty = "#706F6C";
export const tomato1 = "#FFFCFC";
export const tomato10 = "#DB4324";
export const tomato11 = "#CA3214";
export const tomato12 = "#341711";
export const tomato2 = "#FFF8F7";
export const tomato3 = "#FFF0EE";
export const tomato4 = "#FFE6E2";
export const tomato5 = "#FDD8D3";
export const tomato6 = "#FAC7BE";
export const tomato7 = "#F3B0A2";
export const tomato8 = "#EA9280";
export const tomato9 = "#E54D2E";
export const tomatoDark1 = "#1D1412";
export const tomatoDark10 = "#EC5E41";
export const tomatoDark11 = "#F16A50";
export const tomatoDark12 = "#FEEFEC";
export const tomatoDark2 = "#2A1410";
export const tomatoDark3 = "#3B1813";
export const tomatoDark4 = "#481A14";
export const tomatoDark5 = "#541C15";
export const tomatoDark6 = "#652016";
export const tomatoDark7 = "#7F2315";
export const tomatoDark8 = "#A42A12";
export const tomatoDark9 = "#E54D2E";
export const transparent = "#FFFFFF00";
export const violet1 = "#FDFCFE";
export const violet10 = "#644FC1";
export const violet11 = "#5746AF";
export const violet12 = "#20134B";
export const violet2 = "#FBFAFF";
export const violet3 = "#F5F2FF";
export const violet4 = "#EDE9FE";
export const violet5 = "#E4DEFC";
export const violet6 = "#D7CFF9";
export const violet7 = "#C4B8F3";
export const violet8 = "#AA99EC";
export const violet9 = "#6E56CF";
export const violetDark1 = "#17151F";
export const violetDark10 = "#7C66DC";
export const violetDark11 = "#9E8CFC";
export const violetDark12 = "#F1EEFE";
export const violetDark2 = "#1C172B";
export const violetDark3 = "#251E40";
export const violetDark4 = "#2C2250";
export const violetDark5 = "#32275F";
export const violetDark6 = "#392C72";
export const violetDark7 = "#443592";
export const violetDark8 = "#5842C3";
export const violetDark9 = "#6E56CF";
export const white = "#FFFFFF"; // WARNING: 6 duplicates
export const whiteA1 = "transparent";
export const whiteA10 = "#FFFFFF73";
export const whiteA11 = "#FFFFFF96";
export const whiteA12 = "#FFFFFFEB";
export const whiteA2 = "#FFFFFF03";
export const whiteA3 = "#FFFFFF08";
export const whiteA4 = "#FFFFFF0F";
export const whiteA5 = "#FFFFFF17";
export const whiteA6 = "#FFFFFF1F";
export const whiteA7 = "#FFFFFF2E";
export const whiteA8 = "#FFFFFF40";
export const whiteA9 = "#FFFFFF63";
export const yellow1 = "#FDFDF9";
export const yellow10 = "#F7CE00";
export const yellow11 = "#946800";
export const yellow12 = "#35290F";
export const yellow2 = "#FFFCE8";
export const yellow3 = "#FFFBD1";
export const yellow4 = "#FFF8BB";
export const yellow5 = "#FEF2A4";
export const yellow6 = "#F9E68C";
export const yellow7 = "#EFD36C";
export const yellow8 = "#EBBC00";
export const yellow9 = "#F5D90A";
export const yellowDark1 = "#1C1500";
export const yellowDark10 = "#FFEF5C";
export const yellowDark11 = "#F0C000";
export const yellowDark12 = "#FFFAD1";
export const yellowDark2 = "#221A00";
export const yellowDark3 = "#2C2100";
export const yellowDark4 = "#352800";
export const yellowDark5 = "#3E3000";
export const yellowDark6 = "#493C00";
export const yellowDark7 = "#594A05";
export const yellowDark8 = "#705E00";
export const yellowDark9 = "#F5D90A";
